import React from "react";
import "./Footer.css";
import { MOTILAL_STRINGS } from "../../StringConstant/MotiLalAllConstant";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";

function Footer() {
  const informationArray = [
    // { link: "#", title: "THE CHINTELS SCHOOL" },
    { link: "#", title: "INFRASTRUCTURE" },
    { link: "#", title: "MISSION, VISION & VALUES" },
    { link: "#", title: "FOUNDER'S MESSAGE" },
    { link: "#", title: "FROM THE PRINCIPAL'S DESK" },
    { link: "#", title: "AFFILIATION" },
    { link: "#", title: "SCHOOL ACADEMICS" },
    { link: "#", title: "BEYOND ACADEMICS" },
    { link: "#", title: "ADMISSION PROCEDURE" },
  ];
  const quickArray = [
    { link: "#", title: "LAURELS GALLERY" },
    { link: "#", title: "EVENT GALLERY" },
    { link: "#", title: "VIDEO GALLERY" },
    { link: "#", title: "NEWS & MEDIA" },
    { link: "#", title: "ANNOUNCEMENTS" },
    { link: "#", title: "HOMEWORK & ASSIGNMENTS" },
    { link: "#", title: "ACADEMIC CALENDER" },
    { link: "#", title: "SCHOOL HOLIDAYS" },
    { link: "#", title: "TRANSFER CERTIFICATE FORMAT" },
  ];

  return (
    <div className="w-full  footer-bg-image ">
      <div className="w-full flex justify-center  bg-[#2E405D]/90 py-20 relative ">
        <div className="flex flex-col lg:flex-row  justify-between   w-[95%] lg:w-[70%] tablet:w-[80%]">
          <div className="flex flex-col items-center justify-center text-center lg:text-start lg:items-start lg:justify-start ">
            <div className="flex items-center gap-3 font-extrabold text-white text-lg">
              <div className="bg-red-600 w-[5px] text-transparent">|</div>
              <p className="">INFORMATION</p>
              <div className="bg-red-600 w-[5px] text-transparent lg:hidden">
                |
              </div>
            </div>
            <ul className="my-10">
              {informationArray?.map((items, index) => (
                <li
                  key={index}
                  className="uppercase text-xs font-bold text-white hover:text-red-600 mb-3 cursor-pointer"
                >
                  {items?.title}
                </li>
              ))}
            </ul>
          </div>{" "}
          <div className="flex flex-col items-center justify-center text-center lg:text-start lg:items-start lg:justify-start">
            <div className="flex items-center gap-3 font-extrabold text-white text-lg">
              <div className="bg-red-600 w-[5px] text-transparent">|</div>
              <p className="">QUICK LINKS</p>
              <div className="bg-red-600 w-[5px] text-transparent lg:hidden">
                |
              </div>
            </div>
            <ul className="my-10">
              {quickArray?.map((items, index) => (
                <li
                  key={index}
                  className="uppercase text-xs font-bold text-white hover:text-red-600 mb-3 cursor-pointer"
                >
                  {items?.title}
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col items-center justify-center text-center lg:text-start lg:items-start lg:justify-start">
            <div className="flex items-center gap-3 font-extrabold text-white text-lg">
              <div className="bg-red-600 w-[5px] text-transparent">|</div>
              <p className="">CONTACTS</p>
              <div className="bg-red-600 w-[5px] text-transparent lg:hidden">
                |
              </div>
            </div>
            <div className="flex flex-col gap-2 font-bold my-10 mb-5 text-xs">
              <p className="text-[#aeb1b3]">PHONE</p>
              <div className="flex flex-col gap-2 ">
                <p className="text-white hover:text-red-600 cursor-pointer">
                  {MOTILAL_STRINGS?.SCHOOL_PHONE_NO}
                </p>
                {/* <p className="text-white hover:text-red-600 cursor-pointer">
                  0512 - 400 1137
                </p> */}
              </div>
            </div>{" "}
            <div className="flex flex-col gap-2 font-bold mb-5 text-xs">
              <p className="text-[#aeb1b3]">ADDRESS</p>
              <div className="flex flex-col gap-2 ">
                <p className="text-white hover:text-red-600 cursor-pointer capitalize font-normal text-sm ">
                  {MOTILAL_STRINGS?.SCHOOL_ADDRESS_ENGLISH}
                </p>
              </div>
            </div>{" "}
            <div className="flex flex-col gap-2 font-bold mb-10 text-xs">
              <p className="text-[#aeb1b3]">EMAIL</p>
              <div className="flex flex-col gap-2 ">
                <p className="text-white hover:text-red-600 cursor-pointer lowercase text-sm font-normal">
                  {MOTILAL_STRINGS?.SCHOOL_EMAIL_ID}
                </p>
              </div>
            </div>
            <div className=" flex justify-center gap-10 ">
              <FaFacebookF className="text-white hover:text-red-600 hover:border-red-600  text-4xl border rounded-full p-2 cursor-pointer  " />
              <FaInstagram className="text-white hover:text-red-600 hover:border-red-600  text-4xl border rounded-full p-2 cursor-pointer  " />
              <FaYoutube className="text-white hover:text-red-600 hover:border-red-600  text-4xl border rounded-full p-2 cursor-pointer  " />
            </div>
          </div>
          {/* <div className="w-full lg:w-[30%] mt-10">
            <img src={require("../../assets/footer20.jpg")} alt="" />
          </div> */}
        </div>
        {/* <img
          src={require("../../assets/footerimage.png")}
          alt=""
          className="absolute bottom-0  h-[5rem] "
        /> */}
      </div>
    </div>
  );
}

export default Footer;
