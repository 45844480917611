import React, { useState } from "react";
// kids

import kids1 from "../../assets/kids/kids1.jpg";
import kids2 from "../../assets/kids/kids2.jpg";
import kids3 from "../../assets/kids/kids3.jpg";
import kids4 from "../../assets/kids/kids4.jpg";
import kids5 from "../../assets/kids/kids5.jpg";
import kids6 from "../../assets/kids/kids6.jpg";
import kids7 from "../../assets/kids/kids7.jpg";
import kids8 from "../../assets/kids/kids8.jpg";
import kids9 from "../../assets/kids/kids9.jpg";
import kids10 from "../../assets/kids/kids10.jpg";


// other

import photo1 from "../../assets/School_photos/galery1.jpg";
import photo2 from "../../assets/School_photos/galery2.jpg";
import photo3 from "../../assets/School_photos/galery3.jpg";
import photo4 from "../../assets/School_photos/galery4.jpg";
import photo5 from "../../assets/School_photos/galery5.jpg";
import photo6 from "../../assets/School_photos/galery6.jpg";
import photo7 from "../../assets/School_photos/galery7.jpg";
import photo8 from "../../assets/School_photos/galery8.jpg";
import photo9 from "../../assets/School_photos/galery9.jpg";
import photo10 from "../../assets/School_photos/galery10.jpg";
import photo11 from "../../assets/School_photos/galery11.jpg";
import photo12 from "../../assets/School_photos/galery12.jpg";
import photo13 from "../../assets/School_photos/galery13.jpg";
import photo14 from "../../assets/School_photos/galery14.jpg";
import photo15 from "../../assets/School_photos/galery15.jpg";
import photo16 from "../../assets/School_photos/galery16.jpg";
import auditorium1 from "../../assets/School_photos/auditorium1.jpg";
import auditorium2 from "../../assets/School_photos/auditorium2.jpg";
import ShowFullImagePopup from "./ShowFullImagePopup";

function MainGallery() {
  const [showFullPhotoPopup, setShowFullPhotoPopup] = useState({
    index: null,
    imagePath: "",
  });
  const [arrayIndex, setArrayIndex] = useState(-1);
  const kids_array = [
    { photo_url: kids1 },
    { photo_url: kids2 },
    { photo_url: kids3 },
    { photo_url: kids4 },
    { photo_url: kids5 },
    { photo_url: kids6 },
    { photo_url: kids7 },
    { photo_url: kids8 },
    { photo_url: kids9 },
    { photo_url: kids10 },
  ];
  const other_photos_array = [
    { photo_url: photo1 },
    { photo_url: photo2 },
    { photo_url: photo3 },
    { photo_url: photo4 },
    { photo_url: photo5 },
    { photo_url: photo6 },
    { photo_url: photo7 },
    { photo_url: photo8 },
    { photo_url: photo9 },
    { photo_url: photo10 },
    { photo_url: photo11 },
    { photo_url: photo12 },
    { photo_url: photo13 },
    { photo_url: photo14 },
    { photo_url: photo15 },
    { photo_url: photo16 },
    { photo_url: auditorium1 },
  ];

  const handleShowImagePopup = (items, index) => {
    if (showFullPhotoPopup.index === index) {
      setShowFullPhotoPopup({ index: null, imagePath: "" });
      setArrayIndex(index);
    } else {
      setShowFullPhotoPopup({
        index: index,
        imagePath: items?.photo_url,
      });
      setArrayIndex(index);
    }
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col  justify-between   w-[95%] lg:w-[80%] tablet:w-[80%]">
        <div className="flex flex-col">
          <p className="text-4xl mb-5 font-semibold"> Sparkle & Shine </p>

          <div className=" w-full flex flex-wrap gap-5 ">
            {kids_array?.map((items, index) => (
              <img
                key={index}
                src={items?.photo_url}
                alt=""
                className="h-[15rem] w-[17rem] hover:scale-105 duration-500 rounded-xl cursor-pointer"
                onClick={() => handleShowImagePopup(items, index)}
              />
            ))}
          </div>
        </div>{" "}
        <div className="flex flex-col">
          <p className="text-4xl my-5 font-semibold">Other Photos </p>

          <div className=" w-full flex flex-wrap gap-5 ">
            {other_photos_array?.map((items, index) => (
              <img
                key={index}
                src={items?.photo_url}
                alt=""
                className="h-[15rem] w-[17rem] hover:scale-105 duration-500 rounded-xl cursor-pointer "
                onClick={() => handleShowImagePopup(items, index)}
              />
            ))}
          </div>
        </div>
      </div>

      {showFullPhotoPopup?.index === arrayIndex && (
        <ShowFullImagePopup
          rightPopup={`animate-rightPopup`}
          showFullPhotoPopup={showFullPhotoPopup}
          setShowFullPhotoPopup={setShowFullPhotoPopup}
          setArrayIndex={setArrayIndex}
        />
      )}
    </div>
  );
}

export default MainGallery;
