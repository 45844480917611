import React, { useEffect, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { VscAccount } from "react-icons/vsc";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { BsFileEarmarkArrowUpFill } from "react-icons/bs";
import { RiEditFill } from "react-icons/ri";
import {
  useAssignClassTeacherMutation,
  useGetTeacherByStaffIdQuery,
} from "../../Redux/ReduxToolkit";
import { Utils } from "../../Utils/Utils";
import axios from "axios";
import { API_CONSTANT } from "../../ApiConstant/ApiConstant";
import { STUDENT_REGISTRATION_FORM_CONSTANT } from "../../StringConstant/MotiLalAllConstant";
import { enqueueSnackbar } from "notistack";
import CircleLoader from "../../AllLoaders/CircleLoader";
import UpdateTeacher from "./UpdateTeacher/UpdateTeacher";

function TeacherFullInfoPopup({
  rightPopup,
  setShowDetailsBox,
  currentStaffId,
  getTeacherAllData,
  getTableDataRefetch,
}) {
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [teacherFullInfoArray, setTeacherFullInfoArray] = useState([]);
  const [academicYear, setAcademicYear] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [studentSection, setStudentSection] = useState("");
  const [getAllStudents, setGetAllStudents] = useState([]);
  const [showAssignClassTeacherPopup, setShowAssignClassTeacherPopup] =
    useState(false);
  const [showStudentTablePopup, setShowStudentTablePopup] = useState(false);
  const {
    data: getTeacherByStaffId,
    isLoading: getTeacherByStaffIdIsLoading,
    refetch: getTeacherByStaffIdRefetch,
  } = useGetTeacherByStaffIdQuery(currentStaffId || "");

  const [assignClassTeacher, { isLoading: assignClassTeacherIsLoading }] =
    useAssignClassTeacherMutation();

  useEffect(() => {
    if (showStudentTablePopup === true) {
      handleGetStudentByStaffId();
    }
  }, [showStudentTablePopup]);

  const handleGetStudentByStaffId = async () => {
    const response = await axios.get(
      `${API_CONSTANT?.BASE_URL_PROD}${API_CONSTANT?.GET_STUDENTS_BY_STAFF_ID}/${currentStaffId}`
    );
    setGetAllStudents(response?.data?.students);
  };

  useEffect(() => {
    if (getTeacherByStaffId) {
      setTeacherFullInfoArray(getTeacherByStaffId?.data);
    }
  }, [getTeacherByStaffId]);

  const handleAssignClassTeacher = async () => {
    if (
      studentClass === undefined ||
      studentClass === null ||
      studentClass === ""
    ) {
      enqueueSnackbar("Choose class  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
    if (
      studentSection === undefined ||
      studentSection === null ||
      studentSection === ""
    ) {
      enqueueSnackbar("Choose section  is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
    if (
      academicYear === undefined ||
      academicYear === null ||
      academicYear === ""
    ) {
      enqueueSnackbar("Choose academic ear is required !", {
        autoHideDuration: 3000,
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }

    const body = {
      staffId: currentStaffId,
      className: studentClass,
      academicYear: academicYear,
      section: studentSection,
      isClassTeacher: true,
    };

    try {
      const response = await assignClassTeacher({
        body: body,
      });
      if (response.data) {
        setShowAssignClassTeacherPopup(false);
        getTeacherByStaffIdRefetch();
      } else {
        console.log("Oops! class teacher is not assign ! ");
      }
    } catch (error) {
      console.error("Mutation failed:", error?.message);
    }
  };

  console.log(getTeacherByStaffId?.data?.staffId);

  return (
    <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-end fixed top-0 left-0   ">
      {(getTeacherByStaffIdIsLoading || assignClassTeacherIsLoading) && (
        <CircleLoader />
      )}
      <div
        className={`bg-white  min-w-[30rem] h-[100vh] tablet:w-[60%]  shadow-lg relative ${rightPopup} `}
      >
        <div className="flex justify-between items-center p-5 border-b pb-5">
          <div className="flex items-center gap-2">
            <VscAccount className=" text-2xl text-gray-400" />
            <p className="text-xl font-semibold text-[#2B60EC] capitalize">
              {`${getTeacherByStaffId?.data?.personalDetails?.firstName} ${getTeacherByStaffId?.data?.personalDetails?.lastName}`}
            </p>
          </div>
          <IoCloseSharp
            className={` text-2xl text cursor-pointer hover:text-gray-700 text-gray-600 hover:rotate-45 duration-300 `}
            onClick={() => setShowDetailsBox(-1)}
          />
        </div>
        <div className="w-full flex justify-end  mt-5">
          {getTeacherByStaffId?.data?.other?.isClassTeacher ? (
            <CustomButton
              label={` View Students`}
              className={`bg-[#ec9b2b] hover:bg-[#efac55] text-white !mx-0 border-none`}
              onClick={() => {
                setShowStudentTablePopup(true);
              }}
            />
          ) : (
            <CustomButton
              label={` Assign Class Teacher`}
              className={`bg-[#2B60EC] hover:bg-[#4867bd] text-white !mx-0`}
              onClick={() => {
                setShowAssignClassTeacherPopup(true);
              }}
            />
          )}
          <CustomButton
            hasIcon={true}
            label={`Edit`}
            className={` borde border-[#2B60EC] text-[#2B60EC] hover:bg-[#2B60EC] hover:text-white `}
            onClick={() => setShowUpdatePopup(true)}
          >
            <RiEditFill />
          </CustomButton>
        </div>

        <div className="p-10 text-sm">
          <table className="table-auto w-full border-collapse border border-gray-200 text-left">
            <tbody>
              <tr className="border-b">
                <th className="px-4 py-2 font-semibold text-[#313639]">
                  Teacher Name
                </th>
                <td className="px-4 py-2 text-gray-500 capitalize">
                  {`${getTeacherByStaffId?.data?.personalDetails?.firstName} ${getTeacherByStaffId?.data?.personalDetails?.lastName}`}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 font-semibold text-[#313639]">
                  Email Id
                </th>
                <td className="px-4 py-2 text-gray-500">
                  {getTeacherByStaffId?.data?.personalDetails?.email}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 font-semibold text-[#313639]">
                  Aadhar No
                </th>
                <td className="px-4 py-2 text-gray-500">
                  {getTeacherByStaffId?.data?.personalDetails?.adharNumber}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 font-semibold text-[#313639]">
                  Mobile No
                </th>
                <td className="px-4 py-2 text-gray-500">
                  +91 {getTeacherByStaffId?.data?.personalDetails?.phone}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 font-semibold text-[#313639]">
                  Date Of Birth
                </th>
                <td className="px-4 py-2 text-gray-500">
                  {Utils?.formatDate(
                    getTeacherByStaffId?.data?.personalDetails?.dob
                  )}
                </td>
              </tr>
              <tr className="border-b">
                <th className="px-4 py-2 font-semibold text-[#313639]">
                  Address
                </th>
                <td className="px-4 py-2 text-gray-500">
                  {`${getTeacherByStaffId?.data?.addressDetails?.houseNumber} 
          ${getTeacherByStaffId?.data?.addressDetails?.addressLine1} 
          ${getTeacherByStaffId?.data?.addressDetails?.addressLine2} 
          ${getTeacherByStaffId?.data?.addressDetails?.city} 
          ${getTeacherByStaffId?.data?.addressDetails?.pinCode}`}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {showStudentTablePopup && (
        <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-start fixed top-0 left-0   ">
          <div
            className={`bg-white  lg:w-[50rem] h-[100vh] tablet:w-[60%]  shadow-lg relative animate-bottomPopup `}
          >
            <div className="flex justify-between items-center p-5 border-b pb-5">
              <div className="flex items-center gap-2">
                <VscAccount className=" text-2xl text-gray-400" />
                <p className="text-xl font-semibold text-[#2B60EC] capitalize">
                  {`${getTeacherByStaffId?.data?.personalDetails?.firstName} ${getTeacherByStaffId?.data?.personalDetails?.lastName}`}
                </p>
              </div>
              <IoCloseSharp
                className={` text-2xl text cursor-pointer hover:text-gray-700 text-gray-600 hover:rotate-45 duration-300 `}
                onClick={() => setShowStudentTablePopup(false)}
              />
            </div>
            <div className=" h-[90vh] overflow-y-scroll no-scrollbar ">
              <div className="p-5 text-sm">
                {getAllStudents?.length > 0 ? (
                  <table className="w-full text-sm bg-white rounded">
                    <thead>
                      <tr className="shadow text-gray-700">
                        <th className=" p-3 text-start">S.No</th>
                        <th className=" p-3 text-start">Scholar Number</th>
                        <th className=" p-3 text-start">Scholar Name</th>
                        <th className=" p-3 text-start">Father Name</th>
                        <th className=" p-3 text-start">Email</th>
                        <th className=" p-3 text-start">Mobile No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getAllStudents?.map((stDetails, index) => (
                        <tr
                          className="shadow text-gray-500 cursor-pointer hover:bg-gray-400/10"
                          key={index}
                        >
                          <td className=" p-3 text-start ">{index + 1}</td>
                          <td className=" p-3 text-start capitalize">
                            {stDetails?.scholarNumber}
                          </td>
                          <td className=" p-3 text-start capitalize">
                            {` ${stDetails?.personalDetails?.firstName} ${stDetails?.personalDetails?.lastName}`}
                          </td>{" "}
                          <td className=" p-3 text-start capitalize">
                            {stDetails?.personalDetails?.fatherName}
                          </td>
                          <td className=" p-3 text-start capitalize">
                            {stDetails?.personalDetails?.email}
                          </td>{" "}
                          <td className=" p-3 text-start capitalize">
                            {stDetails?.personalDetails?.phone}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className="h-[60vh] flex flex-col items-center justify-center text-xl">
                    <img
                      src={require("../../assets/noData_image.png")}
                      alt=""
                      className="w-[10rem]"
                    />
                    <p className="">No students in this class.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showAssignClassTeacherPopup && (
        <div className="w-screen h-screen bg-[rgba(179,179,179,0.8)] flex justify-start fixed top-0 left-0   ">
          <div
            className={`bg-white  lg:w-[30rem] h-[100vh] tablet:w-[60%]  shadow-lg relative animate-bottomPopup `}
          >
            <div className="flex justify-between items-center p-5 border-b pb-5">
              <div className="flex items-center gap-2">
                <VscAccount className=" text-2xl text-gray-400" />
                <p className="text-xl font-semibold text-[#2B60EC] capitalize">
                  {`${getTeacherByStaffId?.data?.personalDetails?.firstName} ${getTeacherByStaffId?.data?.personalDetails?.lastName}`}
                </p>
              </div>
              <IoCloseSharp
                className={` text-2xl text cursor-pointer hover:text-gray-700 text-gray-600 hover:rotate-45 duration-300 `}
                onClick={() => setShowAssignClassTeacherPopup(false)}
              />
            </div>
            <div className="  p-5  ">
              <div className="w-full flex flex-col  gap-5">
                <div className=" w-full">
                  <select
                    value={academicYear}
                    onChange={(e) => setAcademicYear(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm"
                  >
                    <option value="" disabled>
                      Choose academic year
                    </option>
                    {Utils?.generateAcademicYears(5)?.map(
                      (studentClassData, index) => (
                        <option value={studentClassData} key={index}>
                          {studentClassData}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className=" w-full">
                  <select
                    value={studentClass}
                    onChange={(e) => setStudentClass(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm"
                  >
                    <option value="" disabled>
                      Choose class
                    </option>
                    {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_CLASSES_ARRAY?.map(
                      (studentClassData, index) => (
                        <option value={studentClassData} key={index}>
                          {studentClassData}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className=" w-full">
                  <select
                    value={studentSection}
                    onChange={(e) => setStudentSection(e.target.value)}
                    className="w-full p-2 border border-[#E4E7EB] rounded-md text-sm"
                  >
                    <option value="" disabled>
                      Choose section
                    </option>
                    {STUDENT_REGISTRATION_FORM_CONSTANT?.STUDENT_SECTION_ARRAY?.map(
                      (studentSectionData, index) => (
                        <option value={studentSectionData} key={index}>
                          {studentSectionData}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
              <CustomButton
                label={` Save`}
                className={`bg-[#2B60EC] hover:bg-[#4867bd] text-white !mx-0 mt-5`}
                onClick={() => {
                  handleAssignClassTeacher();
                }}
              />
            </div>
          </div>
        </div>
      )}

      {showUpdatePopup && (
        <UpdateTeacher
          bottomPopup={`animate-bottomPopup`}
          setShowUpdatePopup={setShowUpdatePopup}
          teacherFullInfoArray={teacherFullInfoArray}
          getTableDataRefetch={getTableDataRefetch}
        />
      )}
    </div>
  );
}

export default TeacherFullInfoPopup;
