import React, { useContext, useEffect, useState } from "react";
import Lottie from "lottie-react";

import lottieAnimation from "../../assets/404_page _not_found.json";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../Shared/CustomButton/CustomButton";
import { AuthContext } from "../../Provider/AuthProvider";

function AfterLoginUnauthorise() {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const [subDomain, setSubDomain] = useState("");

   useEffect(() => {
     const domain = window.location.hostname;
     const subDomain1 = getSubdomain(domain);
     console.log("Subdomain", subDomain1);
     setSubDomain(subDomain1);
   }, []);

  const getSubdomain = (domain) => {
    const domSplit = domain.split(".");
    let sliceTill = -2;
    var isLocalHost = domSplit.slice(-1)[0] === "localhost";
    if (isLocalHost) sliceTill = -1;
    return domSplit.slice(0, sliceTill).join("");
  };

 

  return (
    <div className="flex justify-center items-center h-screen flex-col ">
      <Lottie
        animationData={lottieAnimation}
        loop={true}
        className="w-[95%] md:w-[70%] h-[40%] md:h-[70vh] "
      />
      <CustomButton
        label={"Go Home"}
        className={`bg-[#4AD0B3] text-white border-none rounded-md hover:bg-[#41bba0] !text-lg md:!text-base`}
        onClick={() => {
          if (subDomain === "erp") {
            navigate(`/${userData?.medium}`);
          } else {
            navigate("/");
          }
          console.log("sdfsa");
        }}
      />
    </div>
  );
}

export default AfterLoginUnauthorise;
